import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "../app/layout/app.component";
import { BarRatingModule } from "ngx-bar-rating";
import { ModalModule } from "ngx-bootstrap/modal";
import { ToastNoAnimationModule, ToastrModule } from 'ngx-toastr';
import { Injectable, ErrorHandler, Injector } from "@angular/core";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
@Injectable()
export class CustomErrorHandlerService implements ErrorHandler {
  constructor(private injector: Injector) { }
  handleError(error: Error) {
    try {
      if (
        error.message &&
        error.message.toLowerCase().indexOf("loading chunk") !== -1
      ) {
        if (confirm("New version available. Load New Version?")) {
          window.location.reload();
        }
      }
    } catch (ex) { }
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BarRatingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ModalModule.forRoot(),
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    }),
  ],
  providers: [{ provide: ErrorHandler, useClass: CustomErrorHandlerService }],
  bootstrap: [AppComponent],
})
export class AppModule { }

