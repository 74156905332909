import { Component, Inject, ViewEncapsulation } from "@angular/core";
import * as video from "twilio-video";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
  title = "seeit-customer-web";
  isParticipentConnected = false;
  activeRoom;
  previewTracks;
  identity;
  roomName;
  constructor(http: HttpClient, @Inject("BASE_URL") baseUrl: string) {
    //this.getToken(http, baseUrl);
  }
}
